import './CompTiaPrograms.css';
const CompTiaPrograms = () => {
  return (
    <>
         <div className="CompTiaPrograms">
    <table className="ProgramTable">
       
        <tbody>
            <tr>
                <td className="ProgramTitle">Core</td>
                <td className="ProgramList">
                    <div className="ProgramFlexContainer">
                        <div className="ProgramItem">Tech+</div>
                        <div className="ProgramItem">A+</div>
                        <div className="ProgramItem">Security+</div>
                        <div className="ProgramItem">IT Fundamentals</div>
                        <div className="ProgramItem">Network+</div>
                        <div className="ProgramItem"></div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="ProgramTitle">Infrastructure</td>
                <td className="ProgramList">
                    <div className="ProgramFlexContainer">
                        <div className="ProgramItem">Cloud+</div>
                        <div className="ProgramItem">Server+</div>
                        <div className="ProgramItem">Linux+</div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="ProgramTitle">Data</td>
                <td className="ProgramList">
                    <div className="ProgramFlexContainer">
                        <div className="ProgramItem">Data+</div>
                        <div className="ProgramItem">DataX</div>
                        <div className="ProgramItem">DataSys+</div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="ProgramTitle">Cybersecurity</td>
                <td className="ProgramList">
                    <div className="ProgramFlexContainer">
                        <div className="ProgramItem">CySA+</div>
                        <div className="ProgramItem">CASP+</div>
                        <div className="ProgramItem">PenTest+</div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="ProgramTitle">Additional Professional</td>
                <td className="ProgramList">
                    <div className="ProgramFlexContainer">
                        <div className="ProgramItem">Cloud Essentials+</div>
                        <div className="ProgramItem">Project+</div>
                        <div className="ProgramItem"></div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
    </>
  )
}

export default CompTiaPrograms
