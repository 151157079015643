import { useState} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import './TrainingForm.css';
const TrainingForm = ({courseName,courseDate}) => {
    const [formData, setFormData] = useState({
      Name: '',
      CandidateTitle: '',
      Training:courseName,
      TrainingDate:courseDate,
      Email: '',
      MobileNumber: '',
      Country: '',
      NumberOfSeats: '',
      Company: '',
      Message: ''
    });
    const handleInputChange = (e) =>{
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    const TrainingFormHandler=async(event)=>{
    event.preventDefault();
    if(!event.target.Name.value || !event.target.CandidateTitle.value || !event.target.Email.value || !event.target.MobileNumber.value || !event.target.Training.value
     || !event.target.TrainingDate.value || !event.target.Country.value || !event.target.NumberOfSeats.value || !event.target.Company.value )
     {
      toast.warn("Please Fill Required Fields");
    }
    else{
      try {
        setFormData((prevData) => ({
          ...prevData,
          Training:event.target.Training.value,
          TrainingDate:event.target.TrainingDate.value
        }));
        const response = await fetch(process.env.REACT_APP_Training_API || '../../Apis/Training.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
    
        if (!response.ok) {
          const errorText = await response.text(); 
          throw new Error(`HTTP error! status: ${response.status}, ${errorText}`);
        }
        else{
        await response.json(); 
        const formsPreeResponse = await fetch('https://formspree.io/f/mzbnqrne', {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: {
              'Accept': 'application/json',
          },
      });
      if (!formsPreeResponse.ok) {
          const errorText = await formsPreeResponse.text();
          throw new Error(`Formspree error! status: ${formsPreeResponse.status}, ${errorText}`);
      }
        await formsPreeResponse.json();
        toast.success("Thanks for reaching out! We've received your message");
        }
          } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        toast.warn("We Couldn't Receive Your Message Please Try Again Later");
              }
            }
      ReactGA.event({
        category: 'Form',
        action: 'Form Submission',
        label: 'Training Form' 
      });
    }
  return (
    <>
         <div className="PageContainer">
         <ToastContainer />
<form  className="ContactForm" method="post" id="TrainingForm" onSubmit={TrainingFormHandler}>
<label  htmlFor="Training">Training<br/>
    <input type="text" name="Training" id="Training" value={courseName} readOnly/>
</label>
<label  htmlFor="TrainingDate">Training Start Date <br/>
    <input type="text" name="TrainingDate" id="TrainingDate" value={courseDate} readOnly/>
</label>
<label htmlFor="Name" >Full Name <span id="NameAsterik">*</span><br/>
    <input type="Text" name="Name" id="Name" placeholder="Your Name Goes Here..."  value={formData.Name} onChange={handleInputChange} autoComplete='Name'/>
</label>
<label  htmlFor="CandidateTitle">Job Title <span id="TitleAsterik">*</span><br/>
    <input type="text" name="CandidateTitle" id="CandidateTitle" placeholder="Your Job Title Goes Here..." value={formData.CandidateTitle} onChange={handleInputChange}/>
</label>
<label  htmlFor="Email">Email <span id="EmailAsterik">*</span><br/>
    <input type="email" name="Email" id="Email"placeholder="Your Email Goes Here..."  onChange={handleInputChange} value={formData.Email} autoComplete='Email'/>
</label>
<label  htmlFor="MobileNumber">Mobile Number <span id="MobileNumberAsterik">*</span><br/>
    <input type="text" name="MobileNumber" id="MobileNumber" value={formData.MobileNumber}  onChange={handleInputChange} placeholder="Your Mobile Number Goes Here..." />
</label>
<label  htmlFor="Country">Country <span id="CountryAsterik">*</span><br/>
    <input type="text" name="Country" id="Country" placeholder="Your Country Goes Here..." value={formData.Country} onChange={handleInputChange}  autoComplete='Country'/>
</label>
<label  htmlFor="Company">Company <span id="CompanyAsterik">*</span><br/>
    <input type="text" name="Company" id="Company" placeholder="Your Company Goes Here..."  value={formData.Company} onChange={handleInputChange} autoComplete='Company'/>
</label>
<label  htmlFor="NumberOfSeats">Number Of Seats <span id="SeatsAsterik">*</span><br/>
    <input type="number" name="NumberOfSeats" id="NumberOfSeats" placeholder="No. Of Seats Goes Here..."value={formData.NumberOfSeats} onChange={handleInputChange} />
</label>
<label htmlFor="ContactMessage">Message<br/>
    <textarea name="Message" id="ContactMessage" placeholder="Your text Goes Here..."  value={formData.Message} onChange={handleInputChange}></textarea>  
</label>

<button type="submit" className="Button" id="SubmitButton">Send</button>
</form>
</div>
    </>
  )
}


export default TrainingForm
