import JobImg from '../../Images/Contact-us.svg';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import ReactGA from 'react-ga4';
import './JobsPage.css'
const JobsPage = () => {
  const JobDetailsClicked = ()=>{
    ReactGA.event({
      category: 'Button',
      action: 'Click', 
      label: 'Available Positions Clicked'
    });
  }
  return (
    <>
    <Helmet>
        <meta name="description" content=""Take the next step in your career journey with Itqan Hub />
        <meta name="keywords" content="job opportunities, careers, hiring, ItqanHub jobs, employment, job openings, career advancement" />
    </Helmet>
      <section className="JobsSection">
    <h1 className="Title">
        Join ITQAN HUB
    </h1>
    <h3 className="JobsSubHeading blue-text">Kindly share your CV with us at <a className="green-text" href="mailto:hr@itqanhub.com">hr@itqanhub.com</a>, and we'll be in touch once a suitable vacancy aligns with your profile</h3>
    <hr />
    <RouterLink to="/JobDetails"onClick={JobDetailsClicked} className="AvailablePositionsLink blue-text">Available Vacancies</RouterLink>
    <img className="JobsVector" src={JobImg} alt="Send Us Email"/>
   </section>
    </>
  )
}

export default JobsPage
