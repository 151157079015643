import { NavLink } from 'react-router-dom'
import './Placeholder.css'
const Placeholder = ({img,text,buttonText,buttonRoute}) => {
  return (
    <>
     <div className="PlaceholderDiv">
 <img src={img} className="PlaceholderImage" alt="Placeholder"/>
 <div className="TextContainer">
 <h3>{text}</h3>
 </div>
 {buttonText && buttonRoute && (<NavLink to={buttonRoute}><button className="Button">{buttonText}</button></NavLink>)}
  </div>
    </>
  )
}

export default Placeholder
