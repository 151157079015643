import { useState,useEffect,useContext } from 'react';
import { AppContext } from '../../App';
import Loader from '../Loader/Loader';
import { useParams } from 'react-router';
const BlogCardDetails = () => {
  const [blog,setBlog] = useState("");
  const {FormatDate} = useContext(AppContext);
  const [isLoading,setIsLoading] = useState(true);
  const [imgSrc, setImgSrc] = useState(`https://itqanhub.com/BlogsImages/${blog.PngName}.png`);
  const {id} = useParams();
  function ReplaceTags(text) {
    return text
        .replace(/INSERTLINE/g, '<br>')  
        .replace(/STARTBOLD/g, '<strong>') 
        .replace(/ENDBOLD/g, '</strong>');
}
  useEffect(() => { 
    fetch( `https://itqanhub.com/Apis/BlogById.php?id=${id}`,{
      method: 'GET',
      headers: {
       'Content-Type': 'application/json'
    },
    }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        return response.json();
      })
      .then((fetchedData) => {
        setBlog(fetchedData);
        setImgSrc(`https://itqanhub.com/BlogsImages/${fetchedData.PngName}.png`);
        setIsLoading(false);
      })
      .catch((error) => {
      
      });
  }, []);
  return (
    <>
    {isLoading &&<Loader/>}
    {id && blog? <div className='blog-card'>
    <div className="blog-cover">
    <div className="blog-header card-header">
   <h5 className="blog-title pascalCase-text Title">{blog.Title} </h5>
   <h5 className="blog-date ">{FormatDate(blog.BlogDate)}</h5>
    </div>
    
  </div>
  <div className="card-body blog-body">
  <img src={imgSrc} className="blog-image" alt="Blog"/>

         <p className="blog-text"
        dangerouslySetInnerHTML={{ __html:ReplaceTags(blog.BlogText)}}>
        </p> 
        <p>
         
        </p>
    </div>
   
    </div>:null}
    </>
  )
}

export default BlogCardDetails
