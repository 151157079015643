import { Helmet } from 'react-helmet-async';
import './OurServicesPage.css';
const OurServicesPage = () => {
  return (
    <>
     <Helmet>
        <meta name="description" content=""Take the next step in your career journey with Itqan Hub />
        <meta name="keywords" content="IT services, IT training, professional development, consulting services, technical support, technology solutions, IT consulting, business IT services, software development, IT management" />
    </Helmet>
      <section className="ServicesSection">
        <h2 className="Title">Our Services</h2>
        <div className="DesktopServices">
       <h3 className="DesktopMainParagraph">At <span>ITQAN HUB</span>, we provide value-added services not products. 
        Dedicated to delivering top-notch IT trainings, professional services and consultancy,we prioritize tailoring our offerings to meet the distinct needs and challenges of our diverse clientele. Our team, composed of industry experts and certified professionals, imparts a wealth of knowledge and practical insights in each training session and consultation. 
        </h3>
        <hr/>
        <h2 className="Title">IT training services and upskilling programs
        </h2>
       <h3 className="DesktopMainParagraph">Providing a variety of IT training services, encompassing product trainings, knowledge transfer sessions, vendor accreditations, and sales trainings to enable channel success. 
        We prioritize accessibility and flexibility, ensuring that learners from diverse backgrounds and locations can benefit from our comprehensive range of training formats.
    </h3>

    <h3 className="DesktopMainParagraph" id="LastParagraphIT">With a comprehensive range of training formats including instructor-led sessions, virtual instructor-led trainings, blended and hybrid formats, and self-paced e-learning modules, we ensure accessibility and flexibility for learners from all backgrounds and locations.</h3>
    
        <h2 className="Title">Professional Services and Consultancy
        </h2>
        <h3 className="DesktopMainParagraph"  id="LastParagraph">Guided by expertise and commitment, our team delivers seamless proof of concept (POC) and lab experiences, insightful consultancy for strategic decision-making, solution implementation and enablement, and support services. 
            We are dedicated to elevating your journey through innovation and excellence, ensuring your success at every stage of your technological endeavors.
        </h3>
    </div>
    <div className="MobileServices">
       <h5 className="MobileServicesParagraph">At <span>ITQAN HUB</span>, we provide value-added services not products.</h5>  
       <h5 className="MobileServicesParagraph">  Dedicated to delivering top-notch IT trainings, professional services and consultancy,we prioritize tailoring our offerings to meet the distinct needs and challenges of our diverse clientele.</h5>  
       <h5 className="MobileServicesParagraph" id="LastMainMobile">Our team, composed of industry experts and certified professionals, imparts a wealth of knowledge and practical insights in each training session and consultation.</h5>
       <hr/>
       <h2 className="Title">IT training services and upskilling programs</h2>
       <h5 className="MobileServicesParagraph">Providing a variety of IT training services, encompassing product trainings, knowledge transfer sessions, vendor accreditations, and sales trainings to enable channel success.</h5>
       <h5 className="MobileServicesParagraph"> We prioritize accessibility and flexibility, ensuring that learners from diverse backgrounds and locations can benefit from our comprehensive range of training formats.</h5>
       <h5 className="MobileServicesParagraph" id="LastITMobile">With a comprehensive range of training formats including instructor-led sessions, virtual instructor-led trainings, blended and hybrid formats, and self-paced e-learning modules, we ensure accessibility and flexibility for learners from all backgrounds and locations.</h5>
       <h2 className="Title">Professional Services and Consultancy
    </h2>
    <h5 className="MobileServicesParagraph">Guided by expertise and commitment, our team delivers seamless proof of concept (POC) and lab experiences, insightful consultancy for strategic decision-making, solution implementation and enablement, and support services. </h5>
    <h5 className="MobileServicesParagraph" id="LastMobileService">  We are dedicated to elevating your journey through innovation and excellence, ensuring your success at every stage of your technological endeavors.</h5>
</div>
        </section>
    </>
  )
}

export default OurServicesPage
